import { Redirect, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Men from "./pages/Men";
import Home from "./pages/Home";
import Woman from "./pages/Woman";
import Esthetics from "./pages/Esthetics";
import PresseMedia from "./pages/PresseMedia";
import ContactAvis from "./pages/ContactAvis.tsx";
import Footer from "./components/Footer";
import Recrutement from "./pages/Recrutement";
import MentionLegales from "./pages/MentionsLegales";
import PolitiqueDeConfidentialite from "./pages/PolitiqueDeConfidentialite";

const App = () => {

  return (
    <div >
      <NavBar/>
      <main>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/accueil" />
          </Route>
          <Route path="/accueil">
            <Home />
          </Route>
          <Route path="/homme">
            <Men />
          </Route>
          <Route path="/femme">
            <Woman />
          </Route>
          <Route path="/esthetique">
            <Esthetics />
          </Route>
          <Route
            path="/boutique"
            component={() => {
              window.location.replace('www.google.fr');
              return null;
            }}
          />
          {/* <Route path="/presse-media">
            <PresseMedia />
          </Route> */}
          <Route path="/contact-avis">
            <ContactAvis />
          </Route>
          {/* <Route path="/recrutement">
            <Recrutement />
          </Route> */}
          <Route path="/mentions-legales">
            <MentionLegales/>
          </Route>
          {/* <Route path="/politique-de-confidentialite">
            <PolitiqueDeConfidentialite/>
          </Route> */}
        </Switch>
      </main>
      <Footer />
    </div>
  )
}

export default App;
