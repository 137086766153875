import Card from "../components/Card"
import Prestation from "../components/Prestation"

import Line from "../styles/assets/Line 1.png"
import visage from "../styles/assets/MenV2.png"

const Men = () => {
    return (
        <Card
            nameCard="HOMME"
            imgSrc={visage}
        >
            {/* Prestation Classique */}
            <Prestation
                name="Shampoing Coupe Coiffage"
                price="25"
                time="50min"
            />
            <Prestation
                name="Barbe"
                price="15"
                time="20min"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Etudiant */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Etudiant :</p>
            </div>

            <Prestation
                name="Shampoing Coupe Coiffage"
                price="22"
                time="50min"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Formule */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Formule :</p>
            </div>

            <Prestation
                name="Shampoing Coupe Coiffage Barbe"
                price="35"
                time="1h 15min"
            />
            <Prestation
                name="Couleur Shampoing Coupe Coiffage"
                price="*50"
                time="1h 20min"
            />
            <Prestation
                name="Mèches Shampoing Coupe Coiffage"
                price="*60"
                time="1h 20min"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Soin */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Soin :</p>
            </div>

            <Prestation
                name="Soin Visage - Le flash"
                price="20"
                time="20min"
            />
            <Prestation
                className="pb-3"
                name="Lissage Brésilien"
                price="*70"
                time="1h 20min"
            />

        </Card>





    )
}

export default Men