const Prestation = props => {
    const classes = 'flex justify-between flex-row ' + props.className
    return (
        <div className={classes}>
            <p className="flex items-center pl-5 whitespace-normal sm: w-3/4 text-left leading-4 ">{props.name} </p>
            <div className="leading-4 flex flex-col float-right pr-4 text-sm py-1">
                <p >{props.price}€</p>
                <p className="opacity-50 ">{props.time}</p>
            </div>
        </div>
    )
}

export default Prestation