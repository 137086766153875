const ShowMore = props => {

    const classesShow = props.showMoreOne
    const classes = (classesShow ? "hidden " : "visible ") + props.classNamePrestations
    const classesDiv ="cursor-pointer " + props.className
    const showMoreOne = props.showMoreOne

    return (
        <div className={classesDiv}>
            {showMoreOne ? (
                <div onClick={props.closeShow}>
                    Afficher la suite des prestations {props.prestations}
                </div>
            ) : (
                <div onClick={props.openShow}>
                    Cacher la suite des prestations {props.prestations}
                </div>
            )}

            <div className={classes}>
                {props.children}
            </div>

        </div>
    )
}

export default ShowMore