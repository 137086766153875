import { useState } from "react"
import Card from "../components/Card"
import Prestation from "../components/Prestation"
import ShowMore from "../components/ShowMore"

import Line from "../styles/assets/Line 1.png"
import visage from "../styles/assets/MenV2.png"

const Woman = () => {
    const [showMeches, setShowMeches] = useState(true)
    const [showCoiffureEvenement, setShowCoiffureEvenement] = useState(true)

    const closeShowMeches = () => {
        setShowMeches(!showMeches)
    }

    const openShowMeches = () => {
        setShowMeches(!showMeches)
    }


    const closeShowCoiffureEvenement = () => {
        setShowCoiffureEvenement(!showCoiffureEvenement)
    }

    const openShowCoiffureEvenement = () => {
        setShowCoiffureEvenement(!showCoiffureEvenement)
    }


    return (
        <Card
            nameCard="FEMME"
            imgSrc={visage}
        >
            {/* Prestation Classique */}
            <Prestation
                name="Coupe avec Brushing cheveux courts"
                price="35"
                time="45min"
            />
            <Prestation
                name="Coupe avec Brushing cheveux mi-longs"
                price="*40"
                time="50min"
            />
            <Prestation
                name="Coupe avec Brushing cheveux longs"
                price="*60"
                time="1h 15min"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Shampoing */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Shampoing :</p>
            </div>

            <Prestation
                name="Shampoing Brushing court"
                price="*25"
                time="30min"
            />
            <Prestation
                name="Shampoing Brushing mi-long"
                price="*35"
                time="50min"
            />
            <Prestation
                name="Shampoing Brushing long"
                price="*45"
                time="1h 15min"
            />
            <Prestation
                name="Shampoing Mise en plus"
                price="*45"
                time="30min"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Couleur */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Couleur :</p>
            </div>

            <Prestation
                name="Couleur Shampoing Coupe Coiffage court"
                price="*75"
                time="2h"
            />
            <Prestation
                name="Couleur Shampoing Coupe Coiffage mi-long"
                price="*80"
                time="2h"
            />
            <Prestation
                name="Couleur Shampoing Coupe Coiffage long"
                price="*95"
                time="2h 30min"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Décoloration */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Décoloration :</p>
            </div>

            <Prestation
                name="Shampoing Soin Décoloration Coupe Coiffage cheveux courts"
                price="*100"
                time="3h"
            />
            <Prestation
                name="Shampoing Soin Décoloration Coupe Coiffage cheveux mi-longs"
                price="*150"
                time="4h"
            />
            <Prestation
                name="Shampoing Soin Décoloration Coupe Coiffage cheveux longs"
                price="*200"
                time="5h"
            />

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Méches ombrée balayage */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Méches ombrée balayage :</p>
            </div>

            <Prestation
                name="Balayage / Mèches Shampoing Coupe Coiffage cheveux courts"
                price="*95"
                time="2h 30min"
            />
            <Prestation
                name="Balayage ou Mèches Shampoing Coupe Coiffage cheveux mi-longs"
                price="*135"
                time="3h"
            />
            <Prestation
                name="Balayage & Mèches Shampoing Coupe Coiffage cheveux longs"
                price="*160"
                time="3h 45min"
            />

            <ShowMore
                className="text-center text-sm"
                classNamePrestations="text-base text-right"
                showMoreOne={showMeches}
                closeShow={closeShowMeches}
                openShow={openShowMeches}
                prestations="méches ombrée balayage"
            >
                <Prestation
                    name="Patine Coupe Brushing cheveux longs"
                    price="*80"
                    time="2h"
                />
                <Prestation
                    name="Patine Shampoing Coupe Brushing Cheveux mi-longs"
                    price="*62"
                    time="1h 50min"
                />
                <Prestation
                    name="Patine Coupe Brushing Cheveux courts"
                    price="*57"
                    time="1h 30min"
                />
            </ShowMore>

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Coiffure évènement */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Coiffure évènement :</p>
            </div>

            <Prestation
                name="Chignon"
                price="*100"
                time="45min"
            />
            <Prestation
                name="Chignon volumineux"
                price="*120"
                time="1h"
            />
            <Prestation
                name="Coiffage / Attache"
                price="*30"
                time="30min"
            />
            <ShowMore
                className="text-center text-sm pb-6"
                classNamePrestations="text-base text-right"
                showMoreOne={showCoiffureEvenement}
                closeShow={closeShowCoiffureEvenement}
                openShow={openShowCoiffureEvenement}
                prestations="coiffure évènement"
            >
                <Prestation
                    name="Hollywood wayves / Boucle crantées"
                    price="*50"
                    time="1h"
                />
                <Prestation
                    name="+ Essai"
                    price="60"
                    time="1h"
                />
            </ShowMore>




        </Card>





    )
}

export default Woman