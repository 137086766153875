import Slider from "../components/Slider";

//Partners logo
import babor from "../styles/assets/babor.png"
import keune from "../styles/assets/keune.svg"
import indigo from "../styles/assets/indigo.jpg"
import mounir from "../styles/assets/mounir.jpg"

const Home = () => {

  const Planity = "https://www.planity.com/ch-coiffure-35000-rennes"


  return (
    <div className="">
      <div className="flex items-center justify-center">
        <Slider />
      </div>
      <div className="flex justify-center pt-5">
        <a
          href={Planity}
          target="_blanck"
          className="text-center items-center border-2  py-2 px-8 border-ch-black font-bold text-lg text-ch-white bg-ch-black rounded-sm">
          Prendre rendez-vous
        </a>
      </div>

      <div className="py-6">
        <p className="text-xl font-semibold text-center pb-4">
          Nos Partenaires
        </p>
        <div className="overflow-x-auto flex flex-row overflow-hidden no-scrollbar lg:justify-center">
          <img src={babor} className="px-16 lg:w-80 lg:pt-6 w-4/6 h-4/6 pt-5 md:w-2/6" />
          <img src={keune} className="pr-16 lg:w-80 lg:pt-9 w-4/6 h-4/6 pt-5 md:w-2/6 " />
          <img src={indigo} className="pr-16 lg:w-80 lg:pt-4 w-4/6 h-4/6 md:w-2/6" />
          <img src={mounir} className="pr-16 lg:w-80 w-4/6 h-4/6 md:w-2/6" />
        </div>
      </div>
    </div>
  );
};

export default Home;
