import "../index.css";
import { Link } from "react-router-dom";

import logo from "../styles/assets/logo.png";
import IconAddress from "../styles/assets/map.webp"
import IconMobile from "../styles/assets/phone.webp"
import IconMail from "../styles/assets/mail.webp"
import IconFacebook from "../styles/assets/facebook.webp"
import IconInstagram from "../styles/assets/instagram.webp"
import line from "../styles/assets/Line 2.png"
import line2 from "../styles/assets/Line 6.png"

const Footer = () => {

  const Address = "32 Boulevard de Chézy - 35000 Rennes"
  const AddressLink = "https://urlz.fr/igHe"
  const MobileTel = "tel:+33953270322"
  const Mobile = "09.53.27.03.22"
  const Mail = "contact@chcoiffure.com"
  const MailTo = "mailto:contact@chcoiffure.com"
  const FacebookLink = "https://www.facebook.com/CHCoiffure-104379822278516/"
  const Facebook = "CHCoiffureRennes"
  const InstagramLink = "https://www.instagram.com/chcoiffure_rennes/"
  const Instagram = "chcoiffure_rennes"


  return (
    <div className="bg-ch-black flex flex-col text-ch-white items-center text-sm bottom-0 w-ful">
      <div className="py-4">
        <img src={logo} className=" " />
      </div>
      <p className="font-medium">CH COIFFURE</p>
      <p className="font-medium pt-6">OÙ NOUS TROUVER ?</p>

      <div>
        {/* Address */}
        <div className="flex flex-row pt-5">
          <img src={IconAddress} className="mr-3.5" />
          <a href={AddressLink} target="_blank">{Address}</a>
        </div>
        {/* Mobile */}
        <div className="flex flex-row pt-2.5">
          <img src={IconMobile} className="mr-3.5" />
          <a href={MobileTel}>{Mobile}</a>
        </div>
        {/* Mail */}
        <div className="flex flex-row pt-2.5">
          <img src={IconMail} className="mr-3.5" />
          <a href={MailTo}>{Mail}</a>
        </div>
        {/* Facebook */}
        <div className="flex flex-row pt-2.5">
          <img src={IconFacebook} className="mr-3.5" />
          <a href={FacebookLink} target="_blank">{Facebook}</a>
        </div>
        {/* Instagram */}
        <div className="flex flex-row pt-2.5">
          <img src={IconInstagram} className="mr-3.5" />
          <a href={InstagramLink} target="_blank">{Instagram}</a>
        </div>
      </div>

      <p className="font-medium pt-6">NOS HORAIRES</p>

      <div>
        <div className="flex flex-row mb-1">
          <p>Dimanche</p>
          <p className="ml-52">Fermé</p>
        </div>
        <img src={line2} />
        <div className="flex flex-row mb-1">
          <p>Lundi à Jeudi</p>
          <p className="ml-42">10H - 19H</p>
        </div>
        <img src={line2} />

        <div className="flex flex-row mb-1">
          <p>Vendredi & Samedi</p>
          <p className="ml-horaire">09H - 19H</p>
        </div>
        <img src={line2} />

      </div>

      <p className="font-medium pt-6">LIENS UTILES</p>

      {/* <p className="pt-6"><Link to="/recrutement">Recrutement</Link></p> */}
      <p className="pt-6 pb-7"><Link to="/mentions-legales">Mentions Légales</Link></p>
      {/* <p className="pb-7"><Link to="/politique-de-confidentialite">Politiques de confidentialité</Link></p> */}


      <img src={line} className="w-full md:h-1" />

      <p className="my-7">© - Tout droit réserver</p>

      <p> </p>




    </div>
  );
};

export default Footer;