import React, { useState } from "react";

import menuOpen from "../styles/assets/menuOpen.webp";
import menuClose from "../styles/assets/menuClose.webp";
import logo from "../styles/assets/logoch.png";
import line from "../styles/assets/Line 3.png";
import IconMobile from "../styles/assets/smartphone.webp";

import NavItem from "./NavItem";

import { Link } from "react-router-dom";

const NavBar= () => {
  const [showNav, setShowNav] = useState(false);
  const MobileTel = "tel:+33953270322";
  const Mobile = "09.53.27.03.22";

  const closeNavScroll = () => {
    setShowNav(!showNav)
    document.body.style.position =null;
    document.body.style.width = null
  }
  const openNavScroll = () => {
    setShowNav(!showNav);
    document.body.style.position ="fixed";
    document.body.style.width = "100%"
  }

  return (
    <nav className="lg:flex justify-between items-center bg-white sticky top-0 z-20 bg-ch-white">
      <div className="flex items-center justify-between">
        <Link to="/">
          <img className="w-12 p-2" src={logo} alt="logo" />
        </Link>

        {showNav ? (
          <img
            src={menuOpen}
            onClick={closeNavScroll}
            className="lg:hidden block w-10 h-10 p-2 cursor-pointer"
          />
        ) : (
          <img
            src={menuClose}
            onClick={openNavScroll}
            className="lg:hidden block w-10 h-10 p-2 cursor-pointer"
          />
        )}
      </div>

      <ul
        className={
          (showNav ? "left-0" : "-left-full ") +
          " lg:align-top lg:pr-8 lg:static lg:bg-opacity-0 fixed bottom-0 top-16 flex flex-col lg:flex-row lg:space-x-7 items-center lg:bg-transparent bg-gray-500 lg:w-auto w-full lg:text-gray-500 text-white lg:space-y-0 space-y-1 p-2 transition-left bg-ch-white text-ch-black text-center"
        }
      >
        <NavItem content="Accueil" href="/" onClick={closeNavScroll} />
        <img src={line} className=" lg:hidden "/>
        <NavItem content="Homme" href="/homme" onClick={closeNavScroll} />
        <img src={line} className=" lg:hidden" />
        <NavItem content="Femme" href="/femme" onClick={closeNavScroll} />
        <img src={line} className=" lg:hidden" />
        <NavItem content="Esthétique" href="/esthetique" onClick={closeNavScroll} />
        <img src={line} className=" lg:hidden" />
        <a href="https://www.google.com" className="text-xl font-semibold pt-4 lg:pt-0" target="_blanck" onClick={closeNavScroll} >Boutique</a>
        <img src={line} className=" lg:hidden" />

        <NavItem content="Contact - Avis" href="/contact-avis" onClick={closeNavScroll} />
        <img src={line} className=" lg:hidden" />

        <div className="flex w-full absolute bottom-0 left-0 lg:hidden  text-lg font-semibold">

          <a href="https://www.google.com" className="bg-ch-black text-ch-white w-1/2" target="_block">
            <div className="flex items-center justify-center h-full">Prendre rendez-vous</div>
          </a>

          <div className="border-2 border-ch-black w-1/2 py-2.5 items-center text-center text-ch-black">
            {/* Mobile */}
            <div className="flex flex-row items-center justify-center h-full">
              <img src={IconMobile} className="mr-2 w-6" />
              <a href={MobileTel}>{Mobile}</a>
            </div>
          </div>
        </div>
      </ul>
    </nav>
  );
}

export default NavBar

{/* <NavItem content="Presse - Media" href="/presse-media" onClick={closeNavScroll} />
        <img src={line} className=" lg:hidden" /> */}