import { useState } from "react"
import Card from "../components/Card"
import Prestation from "../components/Prestation"
import ShowMore from "../components/ShowMore"

import Line from "../styles/assets/Line 1.png"
import visage from "../styles/assets/MenV2.png"

const Esthetics = () => {
    const [showOnglerie, setShowOnglerie] = useState(true)
    const [showMakeup, setShowMakeup] = useState(true)

    const closeShowOnglerie = () => {
        setShowOnglerie(!showOnglerie)
    }

    const openShowOnglerie = () => {
        setShowOnglerie(!showOnglerie)
    }

    const closeShowMakeup = () => {
        setShowMakeup(!showMakeup)
    }

    const openShowMakeup = () => {
        setShowMakeup(!showMakeup)
    }

    return (
        <Card
            nameCard="ESTHÉTIQUE"
            imgSrc={visage}
        >
            {/* Prestation Onglerie */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Onglerie :</p>
            </div>

            <Prestation
                name="Manucure Simple (limages, cuticules, huile)"
                price="15"
                time="15min"
            />
            <Prestation
                name="Pose de Vernis Semi-permanent"
                price="30"
                time="45min"
            />
            <Prestation
                name="Pose gel sur ongle naturel + couleur"
                price="40"
                time="1h"
            />

            <ShowMore
                className="text-center text-sm"
                classNamePrestations="text-base text-right"
                showMoreOne={showOnglerie}
                closeShow={closeShowOnglerie}
                openShow={openShowOnglerie}
                prestations="onglerie"
            >
                <Prestation
                    name="Pose de gel avec extensions d'ongles (aux chablons)"
                    price="60"
                    time="1h 50min"
                />
                <Prestation
                    name="Remplissage gel (3 à 4 semaines)"
                    price="50"
                    time="1h 45min"
                />
                <Prestation
                    name="Remplissage gel (+ 4 semaines)"
                    price="60"
                    time="1h 45min"
                />
                <Prestation
                    name="Supplément ongle long / extra long"
                    price="*5"
                    time="10min"
                />
                <Prestation
                    name="Supplément french ou babyboomer"
                    price="5"
                    time="5min"
                />
                <Prestation
                    name="Nail art, babycolor, strass, paillettes, effet sucre ou autres"
                    price="*1"
                    time="10min"
                />
                <Prestation
                    name="Réparation ou extension d'un ongle en gel"
                    price="5"
                    time="10min"
                />
                <Prestation
                    name="Dépose gel + soin"
                    price="25"
                    time="40min"
                />
                <Prestation
                    name="Dépose semi permanent + soin"
                    price="15"
                    time="25min"
                />
                <Prestation
                    name="Démaquillage des ongles (Vernis classique)"
                    price="3"
                    time="5min"
                />
            </ShowMore>

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation MakeUp */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Maquillage professionnel :</p>
            </div>

            <Prestation
                name="Makeup mariée: 1essai + Jour J"
                price="160"
                time="1h"
            />
            <Prestation
                name="Makeup flash jour"
                price="30"
                time="25min"
            />
            <Prestation
                name="Makeup sophistiquée / soirée"
                price="70"
                time="45min"
            />

            <ShowMore
                className="text-center text-sm"
                classNamePrestations="text-base text-right"
                showMoreOne={showMakeup}
                closeShow={closeShowMakeup}
                openShow={openShowMakeup}
                prestations="makeup professionnel"
            >

                <Prestation
                    name="Makeup mariée seul"
                    price="90"
                    time="1h"
                />
                <Prestation
                name="Faux cils + pose"
                price="10"
                time="10min"
            />

            </ShowMore>

            <div className="flex justify-center py-4">
                <img src={Line} className="w-40 lg:w-80 lg:h-0.1" />
            </div>

            {/* Prestation Coiffure évènement */}
            <div className="flex pl-4 pt-1">
                <p className="font-semibold underline">Soin du visage :</p>
            </div>

            <Prestation
                name="Le flash"
                price="20"
                time="20min"
            />
            <Prestation
                name="Soin Dr Babor PRO"
                price="95"
                time="50min"
            />
            <Prestation
                name="Soin Dr Babor"
                price="60"
                time="50min"
            />
            <Prestation
                name="Epilation Sourcils, barbe, duve au fils"
                price="*15"
                time="30min"
            />

        </Card>





    )
}

export default Esthetics