import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useState, FormEvent, ChangeEvent, useRef } from "react";

type FormeState = {
  name: string;
  surname: string;
  mail: string;
  mobile: string;
  message: string;
};
type ServiceMessage = {
  class: string;
  text: string;
};
const ContactAvis = () => {
  const classNameInput =
    "border rounded-md py-2 px-4 w-72 shadow-black shadow-md lg:w-96";
  const classNameDiv = "flex flex-col w-1/2 pt-4 items-center";

  const formId = "ZdYPcfKs";
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaRef = useRef<any>();

  const initialFormStates = {
    name: "",
    surname: "",
    mail: "",
    mobile: "",
    message: "",
  };

  const [formState, setFormState] = useState<FormeState>(initialFormStates);
  const [isValide, setIsValide] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();

  const onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
    setIsValide(!isValide);
  };

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
    };
    try {
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({
        text: "Merci pour votre message, quelqu'un vous contactera sous peu si besoin.",
      });
      setFormState(initialFormStates);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      setMessage({
        text: "Désoler, un problème est survenue. Merci de réssayer ou de contacter le salon.",
      });
    }
  };

  const updateFormControl = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const formKey = id as keyof FormState;
    const updateFormState = { ...formState };
    updateFormState[formKey] = value;
    setFormState(updateFormState);
  };

  return (
    <div className="font-dubai flex flex-col justify-center">
      <p className="text-2xl text-ch-black py-5 text-center">Contact - Avis</p>

      <div className="text-center">
        <p>Votre avis compte pour nous !</p>
        <p>Vous pouvez nous le laisser ici.</p>
        <p className="text-sm">* Champ requis</p>
      </div>
      {message && (
        <div
          className={`pt-6 px-6 font-bold text-xl flex justify-center items-center text-center ${message.class}`}
        >
          {message.text}
        </div>
      )}
      <form
        onSubmit={submitHandler}
        method="POST"
        className="flex flex-col justify-center items-center "
      >
        <div className={classNameDiv}>
          <label htmlFor="name" className="text-left">
            Nom *
          </label>
          <input
            onChange={updateFormControl}
            id="name"
            placeholder="|"
            type="text"
            value={formState.name}
            className={classNameInput}
            required
          />
        </div>

        <div className={classNameDiv}>
          <label htmlFor="surname">Prénom *</label>
          <input
            onChange={updateFormControl}
            id="surname"
            placeholder="|"
            type="text"
            value={formState.surname}
            className={classNameInput}
            required
          />
        </div>

        <div className={classNameDiv}>
          <label htmlFor="mobile">Numéro de téléphone *</label>
          <input
            onChange={updateFormControl}
            id="mobile"
            placeholder="|"
            type="number"
            value={formState.mobile}
            className={classNameInput}
            required
          />
        </div>

        <div className={classNameDiv}>
          <label htmlFor="mail">Adresse mail *</label>
          <input
            onChange={updateFormControl}
            id="mail"
            placeholder="|"
            type="email"
            value={formState.mail}
            className={classNameInput}
            required
          />
        </div>

        <div className="flex flex-col pt-4 items-center">
          <label htmlFor="message" className="">
            Votre message *
          </label>
          <textarea
            onChange={updateFormControl}
            id="message"
            className="border rounded-md py-2 px-4 w-72 shadow-black shadow-md lg:w-96 h-52"
            placeholder="|"
            value={formState.message}
          />
        </div>

        <ReCAPTCHA
            ref={recaptchaRef}
          sitekey="6LdAhV8gAAAAAC85YTMVjNpU1fnmFgigHyXH5f7G"
          onChange={onChangeCaptcha}
          className="py-4 w-96 flex justify-center items-center"
        />

        <button
          className="border rounded-md py-2 px-4 mb-16 w-60 lg:w-80 shadow-black shadow-md hover:bg-ch-black hover:text-ch-whitech hover:border-ch-whitech hover:transition-colors hover:duration-200 "
          disabled={(submitting, !isValide)}
        >
          {submitting ? "Envoie en cours ..." : "Envoyer"}
        </button>
      </form>
    </div>
  );
};

export default ContactAvis;
