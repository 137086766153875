import React, { useState, useEffect, useRef } from "react";

const featuredProducts = [
    "/slider/Entrer.png",
    "/slider/Intérieur.png",
    "/slider/Intérieur_2.png",
    "/slider/Intérieur_3.png",
];

let count = 0;
let slideInterval;
const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slideRef = useRef();

    const removeAnimation = () => {
        slideRef.current.classList.remove("bonjour");
    };

    useEffect(() => {
        slideRef.current.addEventListener("animationend", removeAnimation);

        startSlider();
        return () => {
            pauseSlider();
        };
        // eslint-disable-next-line
    }, []);

    const startSlider = () => {
        slideInterval = setInterval(() => {
            handleOnNextClick();
        }, 5000);
    };

    const pauseSlider = () => {
        clearInterval(slideInterval);
    };

    const handleOnNextClick = () => {
        count = (count + 1) % featuredProducts.length;
        setCurrentIndex(count);
        slideRef.current.classList.add("bonjour");
    };
    return (
        <div ref={slideRef} className="select-none relative flex justify-center items-center text-center md:w-1/2 lg:w-1/3">
            <div className="aspect-w-16 aspect-h-9">
                <img src={featuredProducts[currentIndex]} alt="" className="" />
            </div>
        </div>
    );
}


export default Slider