const Card = props => {

    const Planity = "https://www.planity.com/ch-coiffure-35000-rennes"


    return (
        <div className="font-dubai flex flex-col pb-6 lg:items-center">
            <p className="text-2xl text-ch-black py-5 text-center">
                {props.nameCard}
            </p>

            <img className="lg:w-1/3" src={props.imgSrc} />

            <div className="flex justify-center pt-5">
                <a
                    href={Planity}
                    target="_blanck"
                    className="text-center items-center border-2  py-2 px-5 border-ch-black font-bold text-lg text-ch-white bg-ch-black rounded-sm">
                    Prendre rendez-vous
                </a>

            </div>

            <div className="flex justify-center text-ch-black lg:w-presta">
                <div className="border-0.5 w-85 flex flex-col text-right bg-ch-white drop-shadow-ch my-7 pt-4 lg:w-1/3">
                    {props.children}
                </div>
            </div>
            <p className="flex justify-center -mt-6 text-sm pt-2 pb-4">
                * À partir de
            </p>

        </div>
    )
}

export default Card