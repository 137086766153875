import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = props => {
  return (
    <li className="text-xl font-semibold pt-4 lg:pt-0">
      <NavLink
        className={({ isActive }) =>
          isActive ? "text-blue-500" : "text-gray-500"
        }
        to={props.href}
        onClick={props.onClick}
      >
        {props.content}
      </NavLink>
    </li>
  );
}

export default NavItem
